.productCard{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 190px;
    border: 1px solid #ffffff;
    border-radius: 30px;
    padding: 10px;
    text-align: center;
}

.cardImg{
    width: 300px;
    height: 160px;
    border-radius: 30px;
}

.cardDesc{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 10px;
}
.countCard{
    text-align: center;
}

.icon{
    width: 100%;
    font-size: 25px;
    cursor: pointer;
}

.icon :hover{
    opacity: 0.8;
}

.icon :active{
    opacity: 0.6;
}