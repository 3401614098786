.productCardContent{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    padding: 10px;
    width: 345px;
    height: 280px;
    margin: 10px;
    border: 1px solid #ffffffff;
    border-radius: 30px;

}

.cardImg{
    border-radius: 20px;
    height: 170px;
}

.cardDesc{
    display: flex;
    justify-content: space-between;
    margin: 10px 5px;
    white-space: nowrap;
    max-width: 100%;
}
.cardTitle{
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 245px;
}

.cardPrice{
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 65px;
    font-size: 20px;
}