.cartPageWrapper{
    display: flex;
    width: 100%;
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-end;
}

.cartInform{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 30px;
}

.submitInform{
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-right: 100px;

}
.totalPrice{
    font-size: 22px;
    margin-right: 50px;
}

.submit{
    font-size: 22px;
    padding: 5px 20px;
}