.card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 450px;
    height: 200px;
    border: 1px solid #ffffffff;
    text-align: center;
    margin-left: 15px;
    border-radius: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.img{
    width: 300px;
    height: 180px;
    border-radius: 30px;
    margin-left: 5px;
}

.desc{
    margin-right: 20px;
    font-size: 16px;
}