.order{
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 250px;
    border: 1px solid #ffffffff;
    border-radius: 20px;
    margin-bottom: 10px;

}

.title{
    margin-right: 15px;
    font-size: 20px;

}

.cardsWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 72%;
    height: 100%;
    overflow-y: scroll;
    
}
