.input {
    padding: 10px;
    background: linear-gradient(180deg, rgba(100, 106, 150, 0.4) 0%, rgba(182, 186, 214, 0.25) 100%);
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    outline: none;
    color: #ffffff;
    width: 100%;
}

.input::placeholder {
    color: rgba(255, 255, 255, 0.3)
}

.input:focus{
    background: linear-gradient(180deg, rgba(107, 112, 149, 0.622) 0%, rgba(182, 186, 214, 0.556) 100%);
}


.container {
    margin: 10px 0;
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;

}