.map_container{
    width: 100%;
    height: 85%;
    border-radius: 30px;
}

.input {
    margin-top: 3px;
    padding: 5px;
    background: linear-gradient(180deg, rgba(100, 106, 150, 0.4) 0%, rgba(182, 186, 214, 0.25) 100%);
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    outline: none;
    color: #ffffff;
    width: 100%;
}

.input::placeholder {
    color: rgba(255, 255, 255, 0.3)
}

.input:focus{
    background: linear-gradient(180deg, rgba(107, 112, 149, 0.71) 0%, rgba(182, 186, 214, 0.556) 100%);
}

.list{
    background: linear-gradient(180deg, rgba(100, 106, 150, 0.4) 0%, rgba(182, 186, 214, 0.25) 100%);
    border: 0.5px solid rgba(255, 255, 255, 0.584);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    outline: none;
    color: #ffffff;
}

.option :hover{
    background: linear-gradient(180deg, rgba(107, 112, 149, 0.71) 0%, rgba(182, 186, 214, 0.556) 100%);
}

.duration{
    position: absolute;
    top: 180%;
    left: 10%;
    background-color: white;
    padding: 10px;
    color:black;
}

