.historyPageWrapper{
    margin-top: 20px;
    width: 700px;
    height: 100%;

}


.searchBar{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.search{
    margin: 0;
    display: flex;
    justify-content: center;

}

.submit{
    height: 50px;
    width: 250px;
    margin-bottom: 10px;
}

.loaderBox{
    display: flex;
    justify-content: center;
    width: 100%;
}