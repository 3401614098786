.productsCartBox{
    display: flex;
    width: 50%;
    height: 65vh;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #ffffffff;
    border-radius: 30px;
    overflow-y: scroll;
    padding: 20px 30px;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
}


