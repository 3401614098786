.ShopsWrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    min-width: 25%;
    border-radius: 30px;
    border: 1px solid #ffffffff;
    background-color: rgba(255, 255, 255, 0.136);
    margin-right: 20px;
}

.shopsButton{
    margin: 20px;
}

.inactive{
    box-shadow: inset 0px -2px 1px 1px #c4c4c4;
    opacity: 0.7;
}

