.headerWrapper{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    padding: 10px;
    min-width: 50vw;
    background-color: rgba(255, 255, 255, 0.136);
    border-radius: 30px;
    border: 1px solid rgb(175, 175, 175);

}

