.formContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 65vh;
    border: 1px solid #ffffffff;
    border-radius: 30px;
    align-items: center;
    padding: 30px;
    margin-right: 20px;
    
}

input:invalid {
    border: red solid 3px;
  }
.formInput{
    margin: 2px 0;
}

.formInput input{
    padding: 5px;
}
.map{
    width: 100%;
    height: 100%;
}